<div>
    <qrcode [qrdata]="url" [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
    <small>
        <p>
            <mat-icon>photo_camera</mat-icon>{{ 'share.cameraMsg' | translate }}
        </p>
        <p>
            <mat-icon>qr_code</mat-icon>{{ 'share.qrcodeMsg' | translate }}
        </p>
        <p>
            <mat-icon>ads_click</mat-icon>{{ 'share.tapMsg' | translate }}
        </p>
    </small>
</div>