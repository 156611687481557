import { Component } from '@angular/core';

import { QRCodeModule } from 'angularx-qrcode';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-share',
  standalone: true,
  imports: [
    QRCodeModule,
    MatIconModule,
    TranslateModule,
    AsyncPipe,
  ],
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent {
  public url = window.location.origin;
}
