<mat-accordion>

  @for (item of items$ | async; track item; let isExpanded = $first) {
    <mat-expansion-panel [expanded]="isExpanded" #pan>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon class="listItem" color="{{ item.iconColor }}">{{ item.icon }}</mat-icon>
          {{ item.title }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="hiden-body" [class.show-body]="pan.expanded">
        <div [innerHtml]="item.content"></div>
        @if (item.href) {
          <a mat-icon-button color="accent" href="{{ item.href }}" target="_blank" rel="noopener">
            <mat-icon>open_in_new</mat-icon>
          </a>
        }
        <div class="spacer"></div>
      </div>
    </mat-expansion-panel>
  }

  <mat-expansion-panel #share>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon class="listItem">share</mat-icon>
        {{ 'app.share' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="hiden-body" [class.show-body]="share.expanded">
      @if (share.expanded) {
        <app-share></app-share>
      }
    </div>
  </mat-expansion-panel>
</mat-accordion>